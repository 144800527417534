<template>
  <validation-provider
    ref="provider"
    v-slot="{ errors }"
    :rules="validates"
    :name="name"
    :immediate="false"
  >
    <div
      class="form-group"
      :class="{ 'has-errors': errors.length }"
      :style="{ 'margin-bottom': marginBottom }"
    >
      <label v-if="label" class="form-control-label">
        {{ label }}
        <span v-if="sublabel" class="text-danger">{{ sublabel }}</span>
      </label>
      <vue-tel-input
        v-model="phoneNumber"
        :input-classes="{ 'form-control': true, 'is-invalid': errors.length }"
        :name="name"
        :placeholder="placeholder"
        :autofocus="false"
        :disabled="disabled"
        autocomplete="off"
        :default-country="defaultCountry"
        :preferred-countries="[fallbackCountry]"
        :input-options="{ showDialCode: false, tabindex: false }"
        :only-countries="onlyCountries"
        :ignored-countries="['IO','NR','SH','SX','TK','WF']"
        :disabled-fetching-country="disabledFetchingCountry"
        :dropdown-options="{ disabledDialCode: false }"
        :valid-characters-only="!allowChar"
        mode='international'
        @validate="handleValidate"
        @country-changed="handleCountryChanged"
        @input="handleInput"
      >
      </vue-tel-input>
      <b-form-invalid-feedback v-if="errors.length">
        {{ errors[0] }}
      </b-form-invalid-feedback>
      <span v-if="help" class="help-block">
        <small>{{ help }}</small>
      </span>
    </div>
  </validation-provider>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'

export default {
  components: {
    VueTelInput,
  },

  props: {
    value: {
      type: [String],
      required: false,
      default: '',
    },
    name: {
      type: String,
      default: 'phone_number',
    },
    label: {
      type: String,
      default: '',
    },
    sublabel: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: '',
    },
    disabledFetchingCountry: {
      type: Boolean,
      default: false,
    },
    marginBottom: {
      type: String,
      default: '1rem',
    },
    onlyCountries: {
      type: Array,
      default() {
        return undefined
      },
    },
    defaultCountry: {
      type: String,
      default: '',
    },
    fallbackCountry: {
      type: String,
      default: 'US',
    },
    allowChar: {
      type: Boolean,
      default: false,
    },
    help: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      margin: '',
      phoneNumber: '',
      validates: {
        required: false,
        regex: /.*/,
      },
    }
  },

  watch: {
    value: function(val) {
      this.phoneNumber = val || ''
    },

    rules(val) {
      this.validates.required = false
      if (val && val.match(/required/)) {
        this.validates.required = true
      }
    }
  },

  mounted() {
    this.phoneNumber = this.value || ''
    if (this.rules && this.rules.match(/required/)) {
      this.validates.required = true
    }
  },

  methods: {
    handleValidate(args) {
      if (this.rules.match(/phone/)) {
        if (args.isValid) {
          this.validates.regex = /^.{6,}$/
          this.$emit('input', args.number.input)
        } else {
          this.validates.regex = /^.{36,}$/
        }
      } else {
        this.$emit('input', args.number.input)
        this.validates.regex = /.*/
      }
    },

    handleInput(number, args) {
      this.$emit('onInputChange', args)
      this.$emit('input', number)
    },

    handleCountryChanged(args) {
      this.$emit('selectedCountry', args.iso2)
    },
  },
}
</script>

<style lang="scss">
.form-group {
  &.has-errors {
    .vue-tel-input {
      border-color: #ff5c75;
    }
    .invalid-feedback {
      display: block;
    }
  }
  .vue-tel-input {
    border: 1px solid #e2e7f1;
    &:focus {
      border-color: #5369f8;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}
</style>
